import { generateButtonClasses } from "./ButtonClasses.js";
import type { LinkProps, LinkVariant } from "./LinkProps.js";

export function generateLinkClasses({
	disabled,
	variant,
	asButton,
	isSmallForMobile,
	linkVariant,
	noUnderline,
	linkClass,
}: LinkProps): string {
	if (asButton) {
		return generateButtonClasses({ disabled, variant, isSmallForMobile });
	}

	const linkVariants: Record<LinkVariant, string> = {
		secondary: "text-white decoration-white",
		primary: "text-black decoration-black",
		secondaryIcon: "",
	};
	const variantClass = linkVariants[linkVariant ?? "primary"];
	const disabledClass = disabled && "!text-neutral-400 pointer-events-none";

	return [
		"transition-[text-decoration,color] duration-300 cursor-pointer inline-block group",
		linkClass,
		variantClass,
		noUnderline ? "" : "underline",
		disabledClass,
	]
		.filter(Boolean)
		.join(" ");
}
